import Vue from "vue";
import Router from "vue-router";
import VueBodyClass from "vue-body-class";
import store from "../store";
import {
  Admin,
  AgentUser,
  AgentOrganizer,
  AgentEducation,
  AgentWriteDown,
} from "@/config/roles";
import {
  DEFAULT_ADMIN_URL,
  DEFAULT_AGENT_USER_URL,
  DEFAULT_AGENT_ORGANIZER_URL,
} from "@/config/default_urls";
import api from "@/api";
import {redirectToProperIndex} from "@/router/helpers";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views - Pages
const Login = () => import("@/views/pages/Login");

const Page403 = () => import("@/views/pages/Page403.vue");

const NotFoundUser = () => import("@/views/pages/NotFoundUser.vue");

const BlockedUser = () => import("@/views/pages/BlockedUser.vue");

// Catalog
const Catalog = () => import("@/views/content/shared/catalog/Products.vue");
const StructuralSubdivisionsCatalog = () =>
  import("@/views/content/admin/catalog/StructuralSubdivisionProducts.vue");
const Categories = () =>
  import("@/views/content/shared/catalog/Categories.vue");

const Sections = () => import("@/views/content/admin/catalog/Sections.vue");

const Dealers = () => import("@/views/content/admin/Dealers.vue");

// Agencies
const Agencies = () => import("@/views/content/admin/agencies/Agencies.vue");
const Agents = () => import("@/views/content/admin/agencies/Agents.vue");

// StructuralSubdivisions
const StructuralSubdivisions = () =>
  import("@/views/content/admin/structural_subdivisions/Subdivisions.vue");
const StructuralSubdivisionProducts = () =>
  import("@/views/content/admin/structural_subdivisions/Products.vue");
const StructuralSubdivisionsUsers = () =>
  import("@/views/content/admin/structural_subdivisions/Users.vue");

const StaticPages = () => import("@/views/content/admin/StaticPages.vue");

const Travels = () => import("@/views/content/admin/Travels.vue")

const Mailings = () => import("@/views/content/admin/Mailings.vue");

// Statistics
const DealerStatistics = () =>
  import("@/views/content/admin/statistics/dealer/Dealer.vue");
const EducationStatistics = () =>
  import("@/views/content/admin/statistics/education/Education.vue");
const StructuralSubdivisionStatistics = () =>
  import(
    "@/views/content/admin/statistics/subdivision/StructuralSubdivision.vue"
  );

const Faq = () => import("@/views/content/admin/Faq.vue");

const BuiltInParameters = () =>
  import("@/views/content/admin/BuiltInParameters.vue");

const MainPageBanner = () => import("@/views/content/admin/MainPageBanner.vue");

/*
 Agent-user role
 */

// Order management
const ProductOrders = () =>
  import("@/views/content/agent-user/order_management/ProductOrders.vue");
const StructuralSubdivisionProductOrders = () =>
  import(
    "@/views/content/agent-user/order_management/StructuralSubdivisionProductOrders.vue"
  );

// Statistics
const ProductOrdersStatistics = () =>
  import(
    "@/views/content/agent-user/statistics/DealerProductOrdersStatistics.vue"
  );
const StructuralSubdivisionProductOrdersStatistics = () =>
  import(
    "@/views/content/agent-user/statistics/SubdivisionProductOrdersStatistics.vue"
  );

/*
 Agent-organizer role
 */
const RequestManagement = () =>
  import("@/views/content/agent-organizer/requests/RequestManagement.vue");
const RequestStatistics = () =>
  import("@/views/content/agent-organizer/requests/RequestStatistics.vue");

const RequestEducationManagement = () =>
  import("@/views/content/agent-organizer/education/RequestManagement.vue");
const RequestEducationStatistics = () =>
  import("@/views/content/agent-organizer/education/RequestStatistics.vue");

const RequestWriteDownPoints = () =>
  import("@/views/content/agent-organizer/write-down/RequestWriteDownPoints.vue")
const RequestWriteDownPointsStatistics = () =>
  import("@/views/content/agent-organizer/write-down/RequestWriteDownPointsStatistic.vue")

const loginGuard = (to, from, next) => {
  const token = store.state.account.token;
  const role = store.state.account.accountInfo.role;

  if (token && role) {
    switch (role) {
      case Admin:
        next(DEFAULT_ADMIN_URL);
        return;
      case AgentUser:
        next(DEFAULT_AGENT_USER_URL);
        return;
      case AgentOrganizer:
        next(DEFAULT_AGENT_ORGANIZER_URL);
        return;
    }
  }

  next();
};

const roleGuard = (to, from, next) => {
  let routeWithRole = to.matched.find((route) => route.meta.role);

  if (routeWithRole) {
    const routeRole = routeWithRole.meta.role;
    const userRole = store.state.account.accountInfo.role;

    if (routeRole && (!userRole || userRole != routeRole)) {
      next("/login");
      return;
    }
  }

  next();
};

Vue.use(Router);

function configRoutes() {
  return [
    {
      path: "/",
      name: "",
      component: TheContainer,
      beforeEnter: loginGuard,
      meta: {
        bodyClass:
          "m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default",
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "Авторизация",
      component: Login,
      meta: {
        bodyClass:
          "m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default",
        requiresAuth: true,
      },
      beforeEnter: loginGuard,
    },
    {
      path: "/forbidden",
      name: "Запрещено",
      component: Page403,
      meta: {
        bodyClass:
          "m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default",
        requiresAuth: false,
      },
      beforeEnter: loginGuard,
    },
    {
      path: "/not-found-user",
      name: "Не найден пользователь",
      component: NotFoundUser,
      meta: {
        bodyClass:
          "m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default",
        requiresAuth: false,
      },
      beforeEnter: loginGuard,
    },
    {
      path: "/blocked-user",
      name: "Пользователь заблокирован",
      component: BlockedUser,
      meta: {
        bodyClass:
          "m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default",
        requiresAuth: false,
      },
      beforeEnter: loginGuard,
    },
    {
      path: "/admin",
      redirect: "/admin/catalog",
      name: "Главная",
      component: TheContainer,
      meta: {
        requiresAuth: true,
        role: Admin,
      },
      beforeEnter: roleGuard,
      children: [
        {
          path: "catalog",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "products",
              name: "Товары дилера",
              component: Catalog,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "categories",
              name: "Категории товаров",
              component: Categories,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "sections",
              name: "Разделы",
              component: Sections,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "structural-subdivisions-products",
              name: "Товары СП",
              component: StructuralSubdivisionsCatalog,
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: "dealers",
          name: "Дилеры",
          component: Dealers,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "agencies",
          name: "Агентства",
          component: Agencies,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "agents",
          name: "Агенты",
          component: Agents,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "structural-subdivisions",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "/",
              name: "Структурные подразделения",
              component: StructuralSubdivisions,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "products",
              name: "Товары",
              component: StructuralSubdivisionProducts,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "users",
              name: "Пользователи структурных подразделений",
              component: StructuralSubdivisionsUsers,
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: "static-pages",
          name: "Статические страницы",
          component: StaticPages,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "travels",
          name: "Поездки",
          component: Travels,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "mailings",
          name: "Рассылки ",
          component: Mailings,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "mailings",
          name: "Рассылки ",
          component: Mailings,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "dealer-statistics",
          name: "Статистика по дилерам",
          component: DealerStatistics,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "structural-subdivision-statistics",
          name: "Статистика по структурным подразделениям",
          component: StructuralSubdivisionStatistics,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "detailed-training-statistics",
          name: "Подробная статистика по обучению",
          component: EducationStatistics,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "faq",
          name: "Часто задаваемые вопросы",
          component: Faq,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "built-in-parameters",
          name: "Системные параметры",
          component: BuiltInParameters,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "main-page-banner",
          name: "Баннер на главной странице",
          component: MainPageBanner,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/agent-user",
      redirect: "/agent-user/catalog",
      name: "Главная",
      component: TheContainer,
      meta: {
        requiresAuth: true,
        role: AgentUser,
      },
      beforeEnter: roleGuard,
      children: [
        {
          path: "catalog",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "products",
              name: "Товары дилера",
              component: Catalog,
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "structural-subdivisions-products",
              name: "Товары СП",
              component: StructuralSubdivisionsCatalog,
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: "categories",
          name: "Категории товаров",
          component: Categories,
        },
        {
          path: "product-orders",
          name: "Заказы по дилерам",
          component: ProductOrders,
        },
        {
          path: "structural-subdivision-product-orders",
          name: "Заказы по структурным подразделениям",
          component: StructuralSubdivisionProductOrders,
        },
        {
          path: "product-orders-report",
          name: "Заказы",
          component: ProductOrdersStatistics,
        },
        {
          path: "structural-subdivision-product-orders-statistics",
          name: "Статистика по заказам структурных подразделений ",
          component: StructuralSubdivisionProductOrdersStatistics,
        },
      ],
    },
    {
      path: "/agent-organizer",
      redirect: "/agent-organizer/visit-request-orders",
      name: "Главная",
      component: TheContainer,
      meta: {
        requiresAuth: true,
        role: AgentOrganizer,
      },
      beforeEnter: roleGuard,
      children: [
        {
          path: "visit-request-orders",
          name: "Заявки",
          component: RequestManagement,
        },
        {
          path: "visit-request-orders-statistics",
          name: "Статистика по заявкам",
          component: RequestStatistics,
        },
      ],
    },
    {
      path: "/agent-organizer",
      redirect: "/agent-organizer/education-request-orders",
      name: "Главная",
      component: TheContainer,
      meta: {
        requiresAuth: true,
        role: AgentEducation,
      },
      beforeEnter: roleGuard,
      children: [
        {
          path: "education-request-orders",
          name: "Заявки",
          component: RequestEducationManagement,
        },
        {
          path: "education-request-orders-statistics",
          name: "Статистика по заявкам",
          component: RequestEducationStatistics,
        },
      ],
    },
    {
      path: "/agent-organizer",
      redirect: "/agent-organizer/request-write-down-points",
      name: "Главная",
      component: TheContainer,
      meta: {
        requiresAuth: true,
        role: AgentWriteDown,
      },
      beforeEnter: roleGuard,
      children: [
        {
          path: "request-write-down-points",
          name: "Заявки",
          component: RequestWriteDownPoints,
        },
        {
          path: "request-write-down-points-statistics",
          name: "Статистика по заявкам",
          component: RequestWriteDownPointsStatistics,
        },
      ],
    },
  ];
}

const routes = configRoutes();

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: routes,
});

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach(async (to, from, next) => {
    vueBodyClass.guard(to, next);
    if (to.meta.requiresAuth && !store.state.account.token) {
        if (to.path !== '/login') return next({ path: "/login" });
        try {
            const authResponse = await api.account.login({
                code: to.query.code,
            })
            if (authResponse.data.status === 'unauthorized') {
                window.location.href = authResponse.data.data.loginUrl
            } else if (authResponse.data.status === 'blocked') {
              next({ path: "/blocked" });
            } else if (authResponse.data.status === 'notfound') {
              next({ path: "/not-found-user" });
            } else {
                store.commit('account/setAccount', authResponse.data.data)
                redirectToProperIndex(store.getters['account/role'])
            }
        } catch (e) {
            console.log('err', e)
            if (e.response.status === 403) {
                next({ path: "/forbidden" });
            }
        }
    }
});

export default router;
