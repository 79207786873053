import api from "@/api";
import router from "@/router";
import { error } from "@/lib/toasted";
import { redirectToProperIndex } from "../../router/helpers";
import { client_url } from "@/lib/axios";
import { Admin, AgentUser } from "@/config/roles";

const queryString = require("query-string");

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem("token"),
    accountInfo: localStorage.getItem("accountInfo")
      ? JSON.parse(localStorage.getItem("accountInfo"))
      : {
          name: "",
          role: null,
          userName: "",
          dealerUniqueId: null,
          originalUser: null,
        },
  },
  getters: {
    accountInfo: ({ accountInfo }) => accountInfo,
    role: ({ accountInfo: { role } }) => role,
    isCurrentUserAdministrator: ({ accountInfo: { role } }) => role == Admin,
    isCurrentUserAgentUser: ({ accountInfo: { role } }) => role == AgentUser,
  },
  mutations: {
    setAccount(
      state,
      {
        token = null,
        name = "",
        role = null,
        userName = "",
        dealerUniqueId = null,
        originalUser = null,
      }
    ) {
      localStorage.setItem("token", token);
      state.token = token;

      const accountInfo = {
        name,
        role,
        userName,
        dealerUniqueId,
        originalUser,
      };

      localStorage.setItem("accountInfo", JSON.stringify(accountInfo));

      state.accountInfo = accountInfo;
    },
  },
  actions: {
    async authenticate({ commit, getters }, credentials) {
      try {
        const { data } = await api.account.login(credentials);

        commit("setAccount", data.data);

        const { role } = getters;
        redirectToProperIndex(role);
      } catch (e) {
        const data = e.response.data;

        if (e.response.status == 403 && data.status == "error") {
          error(data.message);
        }
      }
    },
    async loginAsAgent({ commit, getters }, agentId) {
      try {
        const {
          data: { data },
        } = await api.agencies.loginAsAgent(agentId);

        commit("setAccount", data);

        const { role } = getters;
        redirectToProperIndex(role);
      } catch (e) {}
    },
    async loginAsSubdivisionUser(_, userId) {
      try {
        const {
          data: { status, data },
        } = await api.account.loginAsSubdivisionUser(userId);

        if (status === "ok") {
          const query = queryString.stringify(data);

          const url = `${client_url}/admin-login?${query}`;
          const win = window.open(url, "_blank");
          win.focus();
        }
      } catch (e) {
        console.error(e);
      }
    },
    async loginAsDealer(_, userId) {
      try {
        const {
          data: { status, data },
        } = await api.account.loginAsDealer(userId);

        if (status === "ok") {
          const query = queryString.stringify(data);

          const url = `${client_url}/admin-login?${query}`;
          const win = window.open(url, "_blank");
          win.focus();
        }
      } catch (e) {
        console.error(e);
      }
    },
    async logout({ commit }) {
      try {
        commit("setAccount", {});
        localStorage.removeItem('token');
        localStorage.removeItem('accountInfo');
        const logoutResponse = await api.account.logout();
        window.location.href = logoutResponse.data;
      } catch (e) {
        console.error(e)
      }
    },
  },
};
